import NextLink from "next/link";

import {
  Box,
  Container,
  Link,
  SimpleGrid,
  Stack,
  Text,
  Flex,
  Icon,
  useColorModeValue,
  Center,
  Heading,
  Avatar,
} from "@chakra-ui/react";

import { CustomImage } from "./ChakraNextImage.js";
import { CustomLinkButton } from "./ChakraNextLinkButton.js";

import { motion } from "framer-motion";

import { footerLinksData, socialLinks } from "../data/footerLinksData";
import { footerLocationData } from "../data/footerLocationData.js";

const ListHeader = ({ children }) => {
  return (
    <Text fontWeight={"500"} fontSize={"xl"} mb={2} letterSpacing={1}>
      {children}
    </Text>
  );
};

export default function Footer() {
  return (
    <Box as="footer" bg="#17172F" color="gray.100">
      <Container as={Stack} maxW={"7xl"} py={10} px={10}>
        <Heading as="h1" fontFamily={"inherit"} mt={4}>
          Our Locations
        </Heading>
        <SimpleGrid columns={{ base: 1, md: 2, lg: 4 }} spacing={8}>
          {footerLocationData.map(({ city, address, imageUrl }, index) => (
            <Center py={6} key={index}>
              <Box
                maxW={"445px"}
                w={"full"}
                bg={"white"}
                boxShadow={"2xl"}
                rounded={"md"}
                p={6}
                overflow={"hidden"}
              >
                <Box
                  h={"210px"}
                  bg={"gray.100"}
                  mt={-6}
                  mx={-6}
                  // mb={6}
                  pos={"relative"}
                >
                  <CustomImage
                    src={imageUrl}
                    height={"100%"}
                    width={"100%"}
                    objectFit="cover"
                    objectPosition="center"
                  />
                </Box>
                <Stack>
                  <Heading
                    color={"#17172F"}
                    fontSize={"2xl"}
                    fontFamily={"body"}
                  >
                    {city}
                  </Heading>
                  <Text color={"gray.700"}>{address}</Text>
                </Stack>
              </Box>
            </Center>
          ))}
        </SimpleGrid>
      </Container>

      <Container as={Stack} maxW={"7xl"} py={10} px={10}>
        <SimpleGrid columns={{ base: 1, sm: 2, lg: 4 }} spacing={8}>
          {footerLinksData.map(({ title, footerLinks }, index) => (
            <Stack key={index} align={"flex-start"}>
              <ListHeader>{title}</ListHeader>

              {footerLinks.map(({ title, url }, index) => (
                <CustomLinkButton
                  variant="ghost"
                  px={0}
                  mt={0}
                  height={8}
                  fontWeight={500}
                  letterSpacing={"0.5px"}
                  _hover={{
                    background: "transparent",
                    transform: "scale(1.05)",
                  }}
                  key={index}
                  href={url}
                >
                  {title}
                </CustomLinkButton>
              ))}
            </Stack>
          ))}

          {socialLinks.map(({ title, footerLinks }, index) => (
            <Stack key={index} align={"flex-start"}>
              <ListHeader>{title}</ListHeader>

              {footerLinks.map(({ title, url, icon }, index) => (
                <Link
                  display={"flex"}
                  alignItems={"center"}
                  justifyContent="center"
                  isExternal
                  variant="ghost"
                  px={0}
                  mt={"8px"}
                  fontWeight={500}
                  letterSpacing={"0.5px"}
                  _hover={{
                    background: "transparent",
                    transform: "scale(1.05)",
                  }}
                  key={index}
                  href={url}
                >
                  <Icon as={icon} w={6} h={6} mr={4} /> {title}
                </Link>
              ))}
            </Stack>
          ))}
        </SimpleGrid>
      </Container>
      <Box py={10}>
        <Flex
          align={"center"}
          _before={{
            content: '""',
            borderBottom: "1px solid",
            borderColor: useColorModeValue("gray.200", "gray.700"),
            flexGrow: 1,
            mr: 8,
          }}
          _after={{
            content: '""',
            borderBottom: "1px solid",
            borderColor: useColorModeValue("gray.200", "gray.700"),
            flexGrow: 1,
            ml: 8,
          }}
        >
          <CustomImage
            as={motion.div}
            whileHover={{
              scale: 1.2,
              rotate: 360,
              transition: { duration: 1 },
            }}
            style={{
              originX: 0.5,
              originY: 0.5,
            }}
            src={"oscar-egegen-light.svg"}
            width={"100px"}
            height={"100px"}
            cursor="pointer"
          />
        </Flex>
        <Text pt={6} fontSize={"sm"} textAlign={"center"}>
          © 2022 egegen. All rights reserved
        </Text>
        <Flex>
          <CustomLinkButton
            variant="ghost"
            px={0}
            mt={0}
            mx={2}
            height={8}
            fontWeight={400}
            letterSpacing={"0.5px"}
            fontSize="sm"
            _hover={{
              background: "transparent",
              transform: "scale(1.05)",
            }}
            href={"/"}
          >
            Terms & Conditions
          </CustomLinkButton>

          <CustomLinkButton
            variant="ghost"
            px={0}
            mt={0}
            mx={2}
            height={8}
            fontWeight={400}
            letterSpacing={"0.5px"}
            fontSize="sm"
            _hover={{
              background: "transparent",
              transform: "scale(1.05)",
            }}
            href={"/"}
          >
            Privacy Policy
          </CustomLinkButton>

          <CustomLinkButton
            variant="ghost"
            px={0}
            mt={0}
            mx={2}
            height={8}
            fontWeight={400}
            letterSpacing={"0.5px"}
            fontSize="sm"
            _hover={{
              background: "transparent",
              transform: "scale(1.05)",
            }}
            href={"/"}
          >
            Privacy Policy 2
          </CustomLinkButton>
        </Flex>
      </Box>
    </Box>
  );
}
