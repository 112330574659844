import { FaFacebook, FaInstagram, FaLinkedin, FaTwitter } from "react-icons/fa";

export const footerLinksData = [
  {
    title: "Company",
    footerLinks: [
      {
        title: "Design Services",
        url: "/services/design-services/",
      },
      {
        title: "Digital Marketing",
        url: "/services/digital-marketing/",
      },
      {
        title: "Software Development",
        url: "/services/software-development/",
      },
      {
        title: "Web Development",
        url: "/services/web-development/",
      },
      {
        title: "Mobile App Development",
        url: "/services/mobile-app-development/",
      },
      {
        title: "DevOps Services",
        url: "/services/devops-services/",
      },
      {
        title: "Staff Augmentation",
        url: "/services/staff-augmentation/",
      },
    ],
  },
  {
    title: "Services",
    footerLinks: [
      {
        title: "Design Services",
        url: "/services/design-services/",
      },
      {
        title: "Digital Marketing",
        url: "/services/digital-marketing/",
      },
      {
        title: "Software Development",
        url: "/services/software-development/",
      },
      {
        title: "Web Development",
        url: "/services/web-development/",
      },
      {
        title: "Mobile App Development",
        url: "/services/mobile-app-development/",
      },
      {
        title: "DevOps Services",
        url: "/services/devops-services/",
      },
      {
        title: "Staff Augmentation",
        url: "/services/staff-augmentation/",
      },
    ],
  },
  {
    title: "Our Brands",
    footerLinks: [
      {
        title: "Design Services",
        url: "/services/design-services/",
      },
      {
        title: "Digital Marketing",
        url: "/services/digital-marketing/",
      },
      {
        title: "Software Development",
        url: "/services/software-development/",
      },
      {
        title: "Web Development",
        url: "/services/web-development/",
      },
      {
        title: "Mobile App Development",
        url: "/services/mobile-app-development/",
      },
      {
        title: "DevOps Services",
        url: "/services/devops-services/",
      },
      {
        title: "Staff Augmentation",
        url: "/services/staff-augmentation/",
      },
    ],
  },
];

export const socialLinks = [
  {
    title: "Follow Us",
    footerLinks: [
      {
        title: "Facebook",
        url: "https://www.facebook.com/egegen",
        icon: FaFacebook,
      },
      {
        title: "Instagram",
        url: "https://www.facebook.com/egegen",
        icon: FaInstagram,
      },
      {
        title: "LinkedIn",
        url: "https://www.facebook.com/egegen",
        icon: FaLinkedin,
      },
      {
        title: "Twitter",
        url: "https://www.facebook.com/egegen",
        icon: FaTwitter,
      },
    ],
  },
];
