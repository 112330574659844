export const footerLocationData = [
  {
    city: "London",
    address: "33 Cavendish Square, Marylebone, Regus W1G 0PW",
    imageUrl: "london.webp",
  },
  {
    city: "Izmir",
    address: "Konak, Cumhuriyet Blv No:34 D:701, 35535 Konak/İzmir",
    imageUrl: "izmir.webp",
  },
  {
    city: "Istanbul",
    address:
      " Esentepe Mah. Dergiler Sk. No: 17/21 D:6 Q Studio Şişli / İSTANBUL",
    imageUrl: "istanbul.webp",
  },
  {
    city: "Manisa",
    address:
      "Teknokent - Celal Bayar Üni. Küme Evler No:22 C Blok Z31 Yunusemre / MANİSA",
    imageUrl: "manisa.webp",
  },
];
