import NextLink from "next/link";
import { useEffect } from "react";

import { motion } from "framer-motion";
import { useState } from "react";
import { CustomImage } from "./ChakraNextImage.js";

import { Box, Flex, Text, IconButton, Stack, Link } from "@chakra-ui/react";

import { HamburgerIcon, CloseIcon } from "@chakra-ui/icons";

import { FaFacebook, FaInstagram, FaLinkedin, FaTwitter } from "react-icons/fa";

const linkColor = "white";
const linkHoverColor = "gray.200";
const mainColor = "#17172F";

const mobileMenuVariants = {
  hidden: {
    opacity: 0,
    scale: 0,
    y: "-100vh",
    x: "-100vw",
  },
  visible: {
    opacity: 1,
    scale: 1,
    y: 0,
    x: 0,
    transition: {
      duration: 0.3,
      staggerChildren: 0.2,
      delayChildren: 0.3,
    },
  },
};

export const Navbar = () => {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);

  const toggleScroll = () => {
    if (mobileMenuOpen) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "unset";
    }
  };

  const onClose = () => {
    setMobileMenuOpen(false);
    toggleScroll();
  };

  const toggleMobileMenu = () => {
    setMobileMenuOpen((prev) => !prev);
    toggleScroll();
  };

  useEffect(() => {
    toggleScroll();
  }, [mobileMenuOpen]);

  return (
    <>
      {/* Desktop Nav */}
      <Flex
        as="nav"
        position="absolute"
        width={"90%"}
        height="117px"
        bg="transparent"
        top="0"
        left="50%"
        sx={{ transform: "translateX(-50%)" }}
        zIndex={5}
        overflow="visible"
        display={{ base: "none", md: "flex" }}
        justify={"space-between"}
      >
        <Flex
          display={{ base: "none", md: "flex" }}
          height={"100%"}
          align="center"
        >
          <DesktopNavLeft />
        </Flex>

        <NextLink href="/">
          <a>
            <CustomImage
              src="egegen-logo.svg"
              width={140}
              height={140}
              alt="egegen logo"
            />
          </a>
        </NextLink>

        <Flex
          display={{ base: "none", md: "flex" }}
          height={"100%"}
          align="center"
        >
          <DesktopNavRight />
        </Flex>
      </Flex>

      {/* Mobile nav */}
      <Box
        as="nav"
        position="absolute"
        background={"transparent"}
        width="100%"
        height="110px"
        top="0"
        left="50%"
        sx={{ transform: "translateX(-50%)" }}
        display={{ base: "flex", md: "none" }}
        zIndex={12}
        justifyContent="center"
        alignItems={"flex-start"}
        flexDirection={"column"}
      >
        <IconButton
          onClick={() => toggleMobileMenu()}
          icon={
            mobileMenuOpen ? (
              <CloseIcon w={5} h={5} color="#ffffff" fontWeight={"600"} />
            ) : (
              <HamburgerIcon w={5} h={5} color="#ffffff" />
            )
          }
          variant={"ghost"}
          aria-label={"Toggle Menu"}
          borderRadius="50%"
          bg={mainColor}
          ml={"1.25rem"}
          zIndex={15}
        />

        <NextLink href="/">
          <Box
            as="a"
            position={"absolute"}
            top="0"
            left="50%"
            sx={{ transform: "translateX(-50%)" }}
          >
            <CustomImage
              src="egegen-logo.svg"
              width={110}
              height={110}
              alt="egegen logo"
            />
          </Box>
        </NextLink>

        <Box
          as={motion.div}
          initial={mobileMenuOpen ? "visible" : "hidden"}
          animate={mobileMenuOpen ? "visible" : "hidden"}
          exit="hidden"
          variants={mobileMenuVariants}
          display={mobileMenuOpen ? "block" : "none"}
          position="absolute"
          width="100vw"
          height="100vh"
          top="0"
          left="0"
        >
          <Stack
            bg={"#17172F"}
            pt={"6rem"}
            align="center"
            width="100%"
            height="100%"
          >
            <Box
              as={motion.div}
              whileTap={{ rotate: "180deg" }}
              style={{ originX: 0.5, originY: 0.5 }}
            >
              <CustomImage
                src="oscar-egegen-light.svg"
                width={90}
                height={90}
                alt="egegen logo"
              />
            </Box>

            {NAV_ITEMS.map(({ label, href }, index) => (
              <Stack key={index} width="100%" p={4} m={0} cursor="pointer">
                <NextLink href={href ?? "/"}>
                  <a onClick={() => onClose()}>
                    <Text
                      width="100%"
                      fontSize={"2xl"}
                      fontWeight={600}
                      color={"white"}
                      textAlign="center"
                      letterSpacing={2}
                    >
                      {label}
                    </Text>
                  </a>
                </NextLink>
              </Stack>
            ))}

            <Flex mt={"2rem"} align="center" justify="center">
              <Link href="https://www.facebook.com/egegen/" isExternal>
                <IconButton
                  aria-label="egegen on Facebook"
                  variant="ghost"
                  fontSize="30px"
                  icon={<FaFacebook />}
                  color="white"
                  mx={4}
                />
              </Link>

              <Link href="https://www.facebook.com/egegen/" isExternal>
                <IconButton
                  aria-label="egegen on Instagram"
                  variant="ghost"
                  fontSize="30px"
                  icon={<FaInstagram />}
                  color="white"
                  mx={4}
                />
              </Link>

              <Link href="https://www.facebook.com/egegen/" isExternal>
                <IconButton
                  aria-label="egegen on LinkedIn"
                  variant="ghost"
                  fontSize="30px"
                  icon={<FaLinkedin />}
                  color="white"
                  mx={4}
                />
              </Link>

              <Link href="https://www.facebook.com/egegen/" isExternal>
                <IconButton
                  aria-label="egegen on Twitter"
                  variant="ghost"
                  fontSize="30px"
                  icon={<FaTwitter />}
                  color="white"
                  mx={4}
                />
              </Link>
            </Flex>
          </Stack>
        </Box>
      </Box>
    </>
  );
};

const DesktopNavLeft = () => {
  return (
    <Stack direction={"row"} spacing={6}>
      {NAV_ITEMS_LEFT.map((navItem) => (
        <NextLink key={navItem.label} href={navItem.href ?? "/"}>
          <a className="hover-underline-animation">
            <Box p={2} mx={2}>
              <Text
                fontSize={"xl"}
                fontWeight={500}
                letterSpacing={1}
                color={linkColor}
                _hover={{
                  textDecoration: "none",
                  color: linkHoverColor,
                }}
              >
                {navItem.label}
              </Text>
            </Box>
          </a>
        </NextLink>
      ))}
    </Stack>
  );
};

const DesktopNavRight = () => {
  return (
    <Stack direction={"row"} spacing={4}>
      {NAV_ITEMS_RIGHT.map((navItem) => (
        <NextLink key={navItem.label} href={navItem.href ?? "/"}>
          <a className="hover-underline-animation">
            <Box p={2} mx={2}>
              <Text
                fontSize={"xl"}
                fontWeight={500}
                letterSpacing={1}
                color={linkColor}
                _hover={{
                  textDecoration: "none",
                  color: linkHoverColor,
                }}
              >
                {navItem.label}
              </Text>
            </Box>
          </a>
        </NextLink>
      ))}
    </Stack>
  );
};

const NAV_ITEMS = [
  {
    label: "Home",
    href: "/",
  },
  {
    label: "Services",
    href: "/services",
  },
  {
    label: "About",
    href: "/about-us",
  },
  {
    label: "Contact",
    href: "/contact-us",
  },
];

const NAV_ITEMS_LEFT = [
  {
    label: "Home",
    href: "/",
  },
  {
    label: "Services",
    href: "/services",
  },
];

const NAV_ITEMS_RIGHT = [
  {
    label: "About",
    href: "/about-us",
  },
  {
    label: "Contact",
    href: "/contact-us",
  },
];
