import "../styles/globals.css";
import { useEffect } from "react";
import Head from "next/head";

import { ChakraProvider, extendTheme, LightMode } from "@chakra-ui/react";

import Layout from "../components/layout";

import { config } from "@fortawesome/fontawesome-svg-core";
import "@fortawesome/fontawesome-svg-core/styles.css";
config.autoAddCss = false;

const myConfig = {
  initialColorMode: "light",
  useSystemColorMode: false,
  colorScheme: null,
  styles: {
    global: {
      "*": {
        color: null,
        fontFamily: null,
        backgroundColor: null,
      },
      body: {
        color: null,
        backgroundColor: null,
        background: null,
      },
    },
  },
};

function MyApp({ Component, pageProps }) {
  useEffect(() => {
    if ("serviceWorker" in navigator) {
      window.addEventListener("load", function () {
        navigator.serviceWorker.register("/sw.js").then(
          function (registration) {
            console.log(
              "Service Worker registration successful with scope: ",
              registration.scope
            );
          },
          function (err) {
            console.log("Service Worker registration failed: ", err);
          }
        );
      });
    }
  }, []);

  return (
    <ChakraProvider theme={extendTheme({ myConfig })} resetCSS={false}>
      <LightMode>
        <Layout>
          <Head>
            <meta
              name="viewport"
              content="width=device-width, initial-scale=1.0"
            />
          </Head>
          <Component {...pageProps} />
        </Layout>
      </LightMode>
    </ChakraProvider>
  );
}

export default MyApp;
