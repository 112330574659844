import React, { useState, useEffect } from "react";
import { IconButton } from "@chakra-ui/react";
import { FaArrowUp } from "react-icons/fa";

const ScrollButton = () => {
  const [visible, setVisible] = useState(false);

  const toggleVisible = () => {
    const scrolled = document.documentElement.scrollTop;
    if (scrolled > 300) {
      setVisible(true);
    } else if (scrolled <= 300) {
      setVisible(false);
    }
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  useEffect(() => {
    window.addEventListener("scroll", toggleVisible);
    return () => {
      window.removeEventListener("scroll", toggleVisible);
    };
  }, []);

  return (
    <IconButton
      aria-label="Scroll to top"
      onClick={scrollToTop}
      icon={<FaArrowUp />}
      position="fixed"
      bottom={"85px"}
      width="42px"
      height="42px"
      right={"20px"}
      padding={0}
      background="rgba(255, 255, 255, 0.85)"
      color="#17172F"
      display={visible ? "flex" : "none"}
      fontSize="20px"
      style={{
        transition: "all 0.3s ease",
      }}
      _hover={{
        background:
          "transparent linear-gradient(45deg, rgba(0, 204, 255, 0.8), rgba(33, 28, 93, 0.8), rgba(212, 0, 212, 0.8)) 0% 0% no-repeat",
        color: "white",
      }}
    />
  );
};

export default ScrollButton;
