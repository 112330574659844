import { Box } from "@chakra-ui/react";
import * as React from "react";
import NextImage from "next/image";
const imageLoader = require("../loader.js");

export const CustomImage = (props) => {
  const { src, alt, objectFit, objectPosition, ...rest } = props;
  return (
    <Box position="relative" {...rest}>
      <NextImage
        loader={imageLoader}
        objectFit={objectFit}
        layout="fill"
        src={src}
        alt={alt}
        objectPosition={objectPosition}
      />
    </Box>
  );
};
