import { useRouter } from "next/router";
import Link from "next/link";

import { useMediaQuery, IconButton } from "@chakra-ui/react";
import { ImBubbles2 } from "react-icons/im";

import { Navbar } from "./Navbar";
// import { Footer } from "./Footer";
import Footer from "./ChakraFooter";
import ScrollButton from "./ScrollButton";

export default function Layout({ children }) {
  const [isMobile] = useMediaQuery("(max-width: 900px");

  const router = useRouter();
  return (
    <>
      <Navbar />
      <main>{children}</main>
      {/* <Footer /> */}
      <Footer />
      <ScrollButton />

      {router.pathname !== "/contact-us" && (
        <Link href="/contact-us">
          <IconButton
            as="a"
            aria-label="Schedule a meeting with us"
            icon={<ImBubbles2 />}
            position="fixed"
            bottom="30px"
            width="42px"
            height="42px"
            right={"20px"}
            padding={0}
            cursor="pointer"
            background="rgba(255, 255, 255, 0.85)"
            color="#17172F"
            fontSize="20px"
            style={{
              transition: "all 0.3s ease",
            }}
            _hover={{
              background:
                "transparent linear-gradient(45deg, rgba(0, 204, 255, 0.8), rgba(33, 28, 93, 0.8), rgba(212, 0, 212, 0.8)) 0% 0% no-repeat",
              color: "white",
            }}
          />
        </Link>
      )}
    </>
  );
}
